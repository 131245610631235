<template>
  <div>
    <th-wrapper :title="$t('pages.settings.check_out.split_receipt.title')">
      <el-switch
        v-model="splitReceipt"
        :aria-label="$t('pages.settings.check_out.split_receipt.title')"
        :active-text="$t('common.interactions.switches.enabled_capital')"
      />
    </th-wrapper>
    <th-wrapper
      :title="$t('pages.settings.check_out.mandatory_customer.title')"
      :info="$t('pages.settings.check_out.mandatory_customer.popover')"
    >
      <el-switch
        v-model="mandatoryCustomer"
        :aria-label="$t('pages.settings.check_out.mandatory_customer.title')"
        :active-text="$t('common.interactions.switches.enabled_capital')"
      />
    </th-wrapper>
    <th-wrapper
      v-if="isTakeawayEnabled"
      :title="$t('pages.settings.check_out.takeaway.title')"
    >
      <el-switch
        v-model="takeaway"
        :aria-label="$t('pages.settings.check_out.takeaway.title')"
        :active-text="$t('common.interactions.switches.enabled_capital')"
      />
    </th-wrapper>
  </div>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    splitReceipt: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'features.split_receipt.enabled'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'features.split_receipt.enabled',
          value: newValue ?? false
        })
      }
    },
    mandatoryCustomer: {
      get: function () {
        return (
          get(
            this.$store.getters['Config/getClientAccountConfiguration'],
            'customers.customer_add_policy'
          ) === 'before'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'customers.customer_add_policy',
          value: newValue ? 'before' : 'none'
        })
      }
    },
    takeaway: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'gastro.takeaway.enabled'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'gastro.takeaway.enabled',
          value: newValue ?? false
        })
      }
    },
    isTakeawayEnabled() {
      return this.$isFeatureEnabled('takeaway')
    }
  }
}
</script>
